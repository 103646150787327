:root {
    --primary-color: rgb(15, 110, 221);
    --secondary-color: ;
}

body {
    width: 100%;
    height: auto;
    font-family: "Catamaran";
}
main {
    background-size: cover;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    /*background-color: rgb(248, 242, 196);*/
}

.header {
    justify-content: space-between;
    margin: 5px;
    background: #fefefe;
}

.headerLogo {
    width: 150px;
    margin-left: 30px;
}
.fsheaderLogo {
    width: 80px;
    opacity: 0.2;
    height: 75px;
    margin-right: 30px;
}
.wrapper {
    /*padding: 70px 0px;*/
    box-shadow: 2px 2px 10px gray;
    margin-top: 4px;
}
.product {
    display: grid;
    height: auto;
    width: 100%;
    padding: 20px;
    position: relative;
}
.product_img {
    border-radius: 60px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 90px 0px rgba(19, 19, 19, 0.09);
    width: 100%;
    height: 290px;
    display: grid;
    place-content: center;
}
.product_img img {
    width: auto;
}
.product_txt {
    display: grid;
    justify-content: space-between;
    padding-left: 20px;
}
.product_txt h3 {
    font-size: 28px;
    color: rgb(28, 27, 47);
    font-weight: 700;
    margin-top: 25px;
}
.product_txt p {
    font-size: 18px;
    color: rgb(28, 27, 47);
    margin-top: 5px;
}
.shapep {
    background-color: var(--primary-color);
    width: 166px;
    height: 1px;
    position: absolute;
    top: 95px;
    right: -95px;
    z-index: 1000;
}

.product_review {
    /*border-radius: 90px;*/
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 90px 0px rgba(19, 19, 19, 0.09);
    width: 100%;
    min-height: 700px;
    /*margin: 20px;*/
    overflow: hidden;
    position: relative;
    padding: 0 50px;
}
.product_review::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /*height: 100px;*/
    background-color: rgb(248, 249, 253);
    z-index: 0;
}
.review_title {
    width: 100%;
    height: 100px;
    display: grid;
    align-items: center;
    padding-top: 15px;
    font-size: 24px;
    color: rgb(41, 41, 41);
    font-weight: 900;
    position: relative;
    z-index: 10;
}
.product_review_inner {
    height: auto;
    padding: 20px 0px;
    width: 100%;
}
.num-heading {
    font-size: 15px;
    color: rgb(41, 41, 41);
    font-weight: 800;
    display: flex;
    align-items: center;
}

form {
    margin-top: 30px;
}
.input_field label {
    font-size: 15px;
    color: rgb(41, 41, 41);
    font-weight: 800;
    display: block;
    margin-bottom: 5px;
}
.input_field .select {
    position: relative;
}
.input_field input,
.input_field .select-toggle,
.input_field .select_no_drop {
    width: 100%;
    border: solid 2px rgb(232, 236, 254);
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 50px;
    margin-bottom: 30px;
    font-size: 15px;
    color: rgb(150, 150, 150);
    padding-left: 15px;
    transition: 0.5s;
}
.input_field input:focus,
.input_field .select-toggle:focus {
    border-color: var(--primary-color);
    outline: none;
}

/* select with dropdown */
.select-toggle,
.select_no_drop {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 15px !important;
    height: 50px !important;
}
.select-toggle.show {
    border-color: var(--primary-color);
}
.select-toggle::after {
    content: "\f107";
    font-family: "Font Awesome 5 free";
    color: rgb(41, 41, 41);
    font-weight: 900;
    font-size: 12px;
    text-align: center;
    line-height: 22px;
    border-radius: 50%;
    background-color: rgb(232, 236, 254);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(-50%, -50%);
}
.select-toggle.show::after {
    background-color: var(--primary-color);
    color: rgb(255, 255, 255);
}
.select-menu {
    padding: 10px 0;
    display: none;
    position: absolute;
    background-color: rgb(255, 255, 255);
    top: 100%;
    left: 0;
    z-index: 1;
    height: auto;
    width: 100%;
    margin: 0;
    margin-top: 1px;
    list-style: none;
    font-size: 15px;
    color: rgb(150, 150, 150);
    padding-left: 30px;
    border: solid 2px rgb(232, 236, 254);
    border-radius: 20px;
    transition: 0.5s;
}
.show.select-menu {
    display: block;
}
.select-option {
    display: block;
    cursor: pointer;
    margin-bottom: 10px;
}
.select-option i,
.select-toggle i {
    font-size: 10px;
    margin-right: 5px;
    border-radius: 50%;
    display: inline-block;
    line-height: 18px;
    text-align: center;
    width: 18px;
    height: 18px;
    background-color: rgb(41, 41, 41);
    color: rgb(255, 255, 255);
}

/* select without dropdown */
.select_no_drop {
    cursor: default;
}
.no-drop-menu {
    width: max-content;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0%, -50%);
    display: inline-flex;
    font-size: 15px;
    color: rgb(150, 150, 150);
}
.no-drop-option {
    display: none;
    align-items: center;
}
.no-drop-option i {
    font-size: 16px;
    color: rgb(41, 41, 41);
    margin-right: 5px;
}
.no-drop-option.show {
    display: flex;
}
.select_no_drop .top_btn,
.select_no_drop .bottom_btn {
    cursor: pointer;
    color: rgb(41, 41, 41);
    font-weight: 900;
    font-size: 12px;
    text-align: center;
    line-height: 22px;
    border-radius: 50%;
    background-color: rgb(232, 236, 254);
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
}
.select_no_drop .top_btn {
    top: 32%;
    transform: translate(-50%, -50%);
}
.select_no_drop .bottom_btn {
    bottom: 32%;
    transform: translate(-50%, 50%);
}

.reaction_inner {
    background-color: rgb(248, 249, 253);
    width: 100%;
    min-height: 140px;
    padding: 10px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.reaction_inner .emoji {
    text-align: center;
    margin: 0 10px;
    cursor: pointer;
}
.reaction_inner .emoji_img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: solid 4px rgb(211, 212, 217);
    padding: 5px;
    transition: 0.5s;
}
.reaction_inner .emoji.active .emoji_img {
    border-color: var(--primary-color);
    background-color: green;
}
.reaction_inner .emoji_img img {
    width: 100%;
}
.reaction_inner .emoji span {
    font-size: 14px;
    color: rgb(41, 41, 41);
    font-weight: bold;
    display: block;
    margin-top: 4px;
}
.reactions {
    margin-bottom: 30px;
}
.info-text {
    font-family: "Montserrat";
    color: rgb(41, 41, 41);
    display: flex;
    align-items: start;
    padding-right: 10px;
    margin-bottom: 30px;
}
.info-text i {
    margin-top: 2px;
}
.info-text h5 {
    font-size: 16px;
    margin-left: 10px;
}
.radio-toggle {
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
}
.radio-toggle span {
    font-size: 14px;
    color: rgb(150, 150, 150);
    font-weight: bold;
    display: inline-block;
    margin-left: 10px;
}
.toggle-onOff {
    background-color: rgb(187, 200, 215);
    width: 34px;
    height: 15px;
    border-radius: 50px;
    padding: 2px;
    cursor: pointer;
    transition: 0.5s;
}
.toggle-btn {
    background-color: rgb(255, 255, 255);
    width: 11px;
    transform: translateX(170%);
    height: 11px;
    border-radius: 50%;
    transition: 0.5s;
}
.toggle-onOff.active {
    background-color: var(--primary-color);
}
.toggle-onOff.active .toggle-btn {
    transform: translateX(0);
}
.review_btn {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}
.review_btn button {
    background-color: rgb(16, 109, 223);
    padding: 0 95px;
    height: 51px;
    border-radius: 50px;
    border: solid 2px transparent;
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    position: relative;
    transition: 0.3s;
}
.review_btn button img {
    width: 32px;
    opacity: 0;
    position: absolute;
    top: 42%;
    right: 75px;
    transform: translate(-50%, -50%);
    transition: 0.5s;
}
.review_btn button:hover img {
    opacity: 1;
    right: 40px;
}
.thankyouPage {
    display: grid;
    align-items: center;
}
.thankyouPage .wrapper {
    margin: 0 auto;
    width: 70%;
}
.thankyouinner {
    border-radius: 40px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 90px 0px rgba(19, 19, 19, 0.08);
    width: 100%;
    min-height: 650px;
    text-align: center;
    display: grid;
    place-content: center;
    padding: 40px;
}
.thankyouinner img {
    width: auto;
    margin: 0 auto;
    margin-bottom: 20px;
}
.thankyouHeading {
    font-size: 40px;
    color: rgb(41, 41, 41);
    font-weight: bold;
    margin-bottom: 30px;
}
.thankyouTxt {
    font-size: 18px;
    color: rgb(41, 41, 41);
    margin: 0 auto;
    width: 57%;
}
.thankyouPage .review_btn {
    justify-content: center;
    margin-top: 40px;
}

.starRatingSec {
    margin-bottom: 10px;
}

.starRating {
    display: flex;
    width: 45%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
}

.smallstar {
    width: 20% !important;
    margin-top: 0px !important;
}

.starRating i {
    border: solid 3px rgb(235, 233, 255);
    background-color: rgb(255, 255, 255);
    width: 18%;
    border-radius: 5px;
    height: 60px;
    font-size: 30px;
    color: rgb(230, 230, 230);
    display: grid;
    place-content: center;
    cursor: pointer;
}

.starRating i.small {
    border: none !important;
}

.starRating i.active,
.starRating i.activehover {
    border-color: rgb(254, 198, 78);
    color: rgb(254, 198, 78);
}
.starRating span {
    display: block;
    font-size: 12px;
    color: rgb(75, 75, 75);
    margin-top: 10px;
}

.inputField {
    margin-bottom: 30px;
}
.inputField .labelTxt,
.inputField label {
    font-size: 14px;
    font-weight: bold;
    display: block;
    width: 100%;
}
.inputField label a {
    color: inherit;
}
.inputField .textField {
    width: 100%;
    height: 60px;
    border: solid 3px rgb(235, 233, 255);
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    padding-left: 20px;
    margin-top: 10px;
}
::placeholder {
    font-size: 12px;
    color: rgb(169, 169, 169);
}
.radioField {
    display: flex;
    width: auto;
    align-items: center;
    height: auto;
    margin-top: 10px;
}
.radioField input {
    -webkit-appearance: none;
    border: solid 3px rgb(235, 233, 255);

    background-color: rgb(255, 255, 255);
    min-width: 33px;
    height: 33px;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
}
.radioField input.radio {
    border-radius: 50%;
}

.radioField input::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: rgb(14 226 99);
    width: 17px;
    height: 17px;
    display: none;
}
.radioField input.radio::before {
    border-radius: 50%;
}
.radioField input:checked::before {
    display: block;
}
textarea {
    padding: 20px;
    height: 100px !important;
    resize: none;
}

.rating {
    margin-bottom: 10px;
}
.ratingInner {
    border-radius: 4px;
    background-color: rgb(245 245 245);
    width: 100%;
    min-height: 140px;
    margin-top: 10px;
    padding: 30px 25px 10px 25px;
}
.ratingSingle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.ratingHeading {
    font-size: 15px;
    color: rgb(8, 8, 8);
    display: flex;
    align-items: center;
    font-weight: 400;
    margin: 0;
}
.ratingHeading::before {
    content: "";
    display: inline-block;
    background-color: rgb(47, 43, 43);
    width: 12px;
    height: 2px;
    margin-right: 12px;
}
.ratingSingle-inner {
    display: flex;
    width: auto;
    height: auto;
    justify-content: space-between;
    align-items: center;
}
.star-fill {
    font-size: 15px;
    color: rgb(230, 230, 230);
    padding: 0 12px;
    border-right: solid 2px rgb(231, 231, 231);
    height: max-content;
    cursor: pointer;
}
.ratingSingle-inner .star-fill:nth-child(5) {
    border: 0;
}
.filled-hover,
.filled {
    color: rgb(234, 176, 0);
}
.total-rating {
    font-size: 15px;
    color: rgb(8, 8, 8);
    font-weight: bold;
    padding-top: 4px;
    margin-left: 20px;
}

.colorred {
    color: red !important;
    border-color: red !important;
}

.colororange {
    color: orange !important;
    border-color: orange !important;
}

.coloryellow {
    color: yellow !important;
    border-color: yellow !important;
}

.colorblue {
    color: #5d6dda !important;
    border-color: #5d6dda !important;
}
.colorlightgreen1 {
    color: #0cf20c !important;
    border-color: #0cf20c !important;
}

.colorgreen {
    color: green !important;
    border-color: green !important;
}
